<template>
  <div class="sticky connexion">
    <!-- En-tête -->
    <!-- <span class = "d-block p-2 bg-primary"></span> -->
    <entete></entete>
    <!-- <span class="d-block p-2 bg-dark"></span> -->
    <main class="bleu">
      <!-- <img src="../assets/svg/Logo_Autoref2.svg" class="desktop-logo-autoref" alt="Logo pour ordinateur"/>
      <img src="../assets/svg/Logo_Autoref2.svg" class="mobile-logo-autoref" alt="Logo pour téléphone" /> -->
        <form class="container">
          <div class="row-inputs">
            <div class="row row-label">
              <label for="log_log">Identifiant</label>
              <label for="pwd_log">Mot de passe</label>
            </div>
            <div class="column md-row row-form">
              <input type="text" class="form-control identifiant" placeholder="Identifiant" v-model="log_log"
                @keyup.enter="focusSuivant('pwd')" v-focus id="log_log" />
              <input type="password" class="form-control mdp" placeholder="Mot de passe" v-model="pwd_log" id="pwd_log"
                ref="pwd" @keyup.enter="focusSuivant('connexion')" />
              <button type="button" id="connexion" ref="connexion" class="btn btn-plain btn-connexion"
                @keyup.enter="VerifierLogin()" @click="VerifierLogin()">
                Se connecter
              </button>
            </div>
              <div class="row row-form-create">
              <button type="button" id="mdp-oublie" class="btn btn-outline btn-mdp-oublie">
                Mot de passe oublié ?
              </button>
            
            </div>
            <!-- ERREUR DE SAISIE - AFFICHAGE DU MESSAGE D'ERREUR POUR L'UTILISATEUR -->
            <div class="alert alert-danger" v-if="retourError">
              Login ou Mot de passe incorrect
            </div>
          </div>
        </form>
    </main>
  </div>
</template>

<script>
import { useRouter } from "vue-router";
import entete from "@/components/comp.entete.vue";
const router = useRouter();
export default {
  name: "index",

  components: {
    entete,
  },
  data() {
    return {
      log_log:'',
      pwd_log:''
    };
  },
  methods: {
    VerifierLogin(){
      const i_log=this.log_log;
      const i_pwd=this.pwd_log;
          //alert(i_log);
          var prog = "./src/req/VerifierLogin.php";
      // envoie des données dans le POSTS
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          i_log: i_log,
          i_pwd: i_pwd
        }),
      };
      fetch(prog, requestOptions)
        .then((response) => response.json())
        .then((data) => {
          if (data.retour == "OK") {
            this.$sessionStorage.set("id_filogin", data.id_filogin),
            this.$sessionStorage.set("log_log", this.log_log),
              this.$sessionStorage.set("pwd_log", this.pwd_log),
              this.$sessionStorage.set("instance", data.ins_adh),
              this.$sessionStorage.set("sta_log", data.sta_log),
              this.$sessionStorage.set("id_adherent", data.id_adherent),
              this.$sessionStorage.set("nom_adh", data.nom_adh),
              this.$router.push({ name: "portail" });
          } else {
            //ERREUR DE SAISIE
            this.retourError = true;
            this.log_log = "";
            this.pwd_log = "";
            document.getElementById("log_log").focus();
          }
        });

    },
    focusSuivant(){

    }
    
  },
};
</script>
