<template>
  <header class="nav-after">
    <div class="container d-flex">
      <a class="logo-header" href="/" title="retour à l'accueil">
        <!-- <img src="../assets/logo-colibri-bleu-blanc.png" class="desktop-logo-colibrisco" alt="Logo pour ordinateur" /> -->
        <img src="../assets/logo-inautoweb-bleu-blanc.svg" class="mobile-logo-colibrisco" alt="Logo pour téléphone" />
      </a>
    </div>
  </header>
</template>

<script>

export default {

  name: "entete",
  components: {
  }
};
</script>
