import { createApp } from 'vue'
import App from './App.vue'
import store from './store'
import './registerServiceWorker'
import router from './router'
import "./css/styles.css";
import StoragePlugin from 'vue-web-storage'
import Swal from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
// import VueMatomo from 'vue-matomo'
// import SimpleTypeahead from 'vue3-simple-typeahead';
// import 'vue3-simple-typeahead/dist/vue3-simple-typeahead.css';
 
const app = createApp(App).use(router).use(store)

// .use(VueMatomo, {
//   host: 'http://matomo.grifc.fr/',
//   siteId: 4,
// })
// .use(SimpleTypeahead)
.use(StoragePlugin, {
  prefix: 'sess_',// default `app_`
  drivers: ['session', 'local'], // default 'local'
});;
//  app.component('EasyDataTable', Vue3EasyDataTable);
app.config.globalProperties.$swal = Swal;
 app.mount('#app');
 window._paq.push(['trackPageView']); //To track pageview
