/* eslint-disable */
import { createRouter, createWebHistory } from 'vue-router'
import PageIndex from '../views/Index.vue'
const routes = [
  {
    path: '/',
    name: 'index',
    component: PageIndex 
  },
  {
    path: '/portail',
    name: 'portail',
    component: () => import('../views/Portail.vue')
  },
  {
    path: '/RechArticle.vue',
    name: 'RechArticle.vue',
    component: () => import('../views/RechArticle.vue')
  },
  {
    path: '/StatU.vue',
    name: 'StatU.vue',
    component: () => import('../views/StatU.vue')
  },
  {
    path: '/StatM.vue',
    name: 'StatM.vue',
    component: () => import('../views/StatM.vue')
  },
  // { 
  //   path: '/graphe', 
  //   name: 'grapheStatU', 
  //   component: GrapheStatU 
  // },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
