import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate'
export default createStore({
  state: {
    gId_fisoc: '',
    gId_fimag: '',
    gId_firep: '',
    gId_univers: '',
    gAnn_sts: '',
    gId_fiadd: '',
  },
  getters: {
  },
  mutations: {
    setgId_fisoc(state, value) {
      state.gId_fisoc = value
    },
    setgId_fimag(state, value) {
      state.gId_fimag = value
    },
    setgId_firep(state, value) {
      state.gId_firep = value
    },
    setgId_univers(state, value) {
      state.gId_univers = value
    },
    setgAnn_sts(state, value) {
      state.gAnn_sts = value
    },
    setgId_fiadd(state, value) {
      state.gId_fiadd = value
    },
  },
  actions: {
  },
  modules: {
  },
  plugins: [createPersistedState()],
})
